import React from "react";
import Layout from "../components/Layout/Layout2"
import { useForm } from "react-hook-form";
import { useState } from 'react';
import EnvelopeImg from "../images/envelopje.png"

export default function App() {
  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm();
  const { formState: {isSubmitting}} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const GATEWAY_URL = 'https://kl0wc6pvpk.execute-api.eu-west-1.amazonaws.com/prod/submit';
  const onSubmit = data => {
  try {
    fetch(GATEWAY_URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    reset();
    alert('Bedankt voor uw boodschap. Uw email werd verzonden. Wij nemen spoedig contact met u op.')
    setSubmitted(true);
  } catch (error) {
    setError('submit', 'submitError', `Doh! ${error.message}`);
  }
};

  return (
	<Layout>
        <section className="pt-10 md:pt-20">
			<div className="container mx-auto px-8 lg:flex">
				<div className="text-center lg:text-left lg:w-1/2">
					<h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
						Contact
					</h1>
					<p className="text-xl lg:text-2xl mt-6 font-light">
						👋  Neem contact met ons op voor meer informatie of voor vragen.
					</p>
					<p className="text-xl lg:text-2xl mt-6 font-light">
						Wij zijn er klaar voor  👇
					</p>
					
					
					<form onSubmit={handleSubmit(onSubmit)} class="w-full max-w-lg pl-4">
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
						      <label htmlFor="name" class="block text-xl lg:text-2xl mt-6 font-light">Naam</label>
						    </div>
						    <div className="md:w-2/3 min-w-full">  
						      <input
						      	className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						        id="name"
						        aria-invalid={errors.firstName ? "true" : "false"}
						        {...register('name', { required: true })}
						      />
						      {errors.name && (
						        <span role="alert">
						          Dit veld is verplicht.
						        </span>
						      )}
							</div>
						</div>
						
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
						      <label htmlFor="email" class="text-xl lg:text-2xl mt-6 font-light">Email</label>
						     </div>
						    <div className="md:w-2/3 min-w-full">  
						      <input
						        id="email"
						        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						        aria-invalid={errors.email ? "true" : "false"}
						        {...register('email', { required: true })}
						      />
						      {errors.email && (
						        <span role="alert">
						          This field is required
						        </span>
						      )}
							</div>
						</div>
						
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
						      <label htmlFor="phone" className="text-xl lg:text-2xl mt-6 font-light">Telefoon</label>
						    </div>
						    <div className="md:w-2/3 min-w-full">  
						      <input
						        id="phone"
						        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						        aria-invalid={errors.phone ? "true" : "false"}
						        {...register('phone', { required: false, maxLength: 30  })}
						      />
						      {errors.phone && (
						        <span role="alert">
						          Dit veld is verplicht.
						        </span>
						      )}
							</div>
						</div>
						
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
						      <label htmlFor="desc" className="text-xl lg:text-2xl mt-6 font-light">Boodschap</label>
						    </div>
						    <div className="md:w-2/3 min-w-full">  
						      <textarea
							  {...register('desc', { required: true, maxLength: 800  })}
							  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							  name="desc"
							  id="desc"
							  rows="3"
							  placeholder="Uw boodschap"
							  />
						      {errors.desc && (
						        <span role="alert">
						          Dit veld is verplicht. 
						        </span>
						      )}
							</div>
						</div>
						
						<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
							<div className="md:w-1/3 min-w-full">
							</div>
							<div className="md:w-2/3 min-w-full"> 
								<div>
							      <input 
								  disabled={isSubmitting} 
								  type="submit" 
								  className="block w-full mb-10 mt-8 border-2 rounded px-6 py-1 bg-primary hover:bg-primary-darker text-white"
								  value="Versturen"
								  />
								</div>
							</div>
						</div>
					</form>		
				</div>
				
				<div className="mr-12 lg:w-80">
					<img src={EnvelopeImg} alt="Listen" />
					<p className="pl-8 mt-4 text-gray-600">
						Steunverkoop is een initiatief van CHATEAU BOHO
					</p>
					<p className="pl-8 mt-4 text-gray-600">
					Schoenstraat 46-48, 2140 BOrgerHOut-Antwerpen.
					</p>
					<p className="pl-8 mt-4 text-gray-600">
						CHATEAU BoHo: BTW BE 0858 304 005
					</p>
				</div>
				
			</div>
		</section>
	</Layout>
  );
}