import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import LogoIconNew from '../../images/luidsprekertje-icon.png';
import { Link } from "gatsby"

import Button from '../Button';

const Header2 = () => (
  <header className="sticky top-0 bg-white shadow">
	  <div className="container mx-auto flex flex-wrap items-center justify-between max-w-7xl mx-auto md:pt-8 md:pb-8 md:pr-5 md:pl-5 text-xl">
	      <Link to="/">
	          <h1 className="flex items-center sidebar-link no-underline">
	            <img src={LogoIconNew} alt="Listen" />
	            <span className="text-xl font-bold tracking-tight">
	              Steunverkoop
	            </span>
	          </h1>
	        </Link>
	      
	      
	      <Link to="/" className="sidebar-link">
	            <button className="sidebar-item">Terug naar de homepagina.</button>
	      </Link>
      </div>
  </header>
);

export default Header2;
